import React from "react";
import styled from "styled-components";

const AboutUs = () => {
  return (
    <Container id="about">
      <Box>
        <h2>About Us</h2>
        <p>
          House of Invention (HOI), based in Riyadh, was founded in 1997 as a
          subsidiary of the esteemed Bugshan Group. From the heart of Saudi
          Arabia, HOI has dedicatedly crafted bespoke, turnkey solutions in
          telecommunication and information technologies, catering to a diverse
          clientele spanning numerous sectors.
        </p>
        <p>
          Over the years, HOI has risen to prominence, solidifying its status as
          one of the top business entities in the region. We specialize in
          offering tailored services in information technology, engineering,
          construction, and operations for the IT, telecommunications, and
          related industries throughout the Middle East.
        </p>
        <p>
          Today, HOI stands as a pillar in the IT industry, renowned for our
          unwavering commitment to quality. Our reputation is anchored in our
          expertise in designing, delivering, and deploying IT and mobile
          solutions across all major platforms. This prowess is backed by our
          robust technical competency, a meticulously defined methodology, and a
          team of dedicated engineers, designers, and developers, all
          collaborating to deliver world-class solutions.
        </p>
      </Box>
      <ImgBox>
        <Img src="https://img.freepik.com/free-photo/technology-human-touch-background-modern-remake-creation-adam_53876-129794.jpg?t=st=1655641314~exp=1655641914~hmac=05b6f75dee0ac0995880362d71679f55c1064b8ad71ea71440486a47e02c3981&w=1380" />
      </ImgBox>
    </Container>
  );
};

export default AboutUs;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  h2,
  p {
    color: #000;
  }

  @media screen and (max-width: 999px) {
    flex-direction: column;
    //height: 50vh;
  }
`;

const Box = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;

  p {
    font-size: 15px;
  }

  @media screen and (max-width: 999px) {
    width: 100%;
    height: 100%;
  }
`;

const ImgBox = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  @media screen and (max-width: 999px) {
    width: 100%;
    height: 50%;
    display: none;
  }
`;

const Img = styled.img`
  height: 100%;
  width: 100%;
  opacity: 0.4;
`;
