import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import Sidebar from '../components/Sidebar';
import NavbarContact from '../components/NavbarContact';

const Container = styled.div`
  width: 100%;
  padding: 40px 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2% 10%;
    h2 {
      text-align: center;
      font-family: 'Cairo', sans-serif;
      text-decoration: underline;
    }
    h4 {
      text-align: center;
      font-family: 'Cairo', sans-serif;
    }
    p {
      text-align: center;
      font-family: 'Cairo', sans-serif;
    }
  `;

const PrivacyAr = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <NavbarContact toggle={toggle} />
      <Container>
      <Content>
        <h2>سياسة الخصوصية وسرية المعلومات</h2>
        <p>
          يصف إشعار الخصوصية هذا كيف يمكننا استخدام معلوماتك إذا كنت: 1- قمت
          بتحميل تطبيق مودك واستخدامه 2- استخدام خدمات التطبيق المتاحة مثل
          المبيعات والتسويق والفعاليات تُستخدم هذه الصفحة لإعلام الزائرين بشأن
          سياساتنا بجمع المعلومات الشخصية واستخدامها والكشف عنها إذا قرر أي شخص
          استخدام خدمتنا. إذا اخترت استخدام خدمتنا ، فأنت توافق على جمع واستخدام
          المعلومات المتعلقة بهذه السياسة. 
          {/* تُستخدم المعلومات الشخصية التي نجمعها
          لتوفير الخدمة وتحسينها. لن نستخدم أو نشارك معلوماتك مع أي شخص باستثناء
          ما هو موضح في سياسة الخصوصية هذه. */}
        </p>
        {/* <h4>المعلومات التي نحصل عليها</h4>
        <p>
          للحصول على تجربة أفضل ، أثناء استخدام خدمتنا ، قد نطلب منك تزويدنا
          بمعلومات تعريف شخصية معينة. سيتم الاحتفاظ بالمعلومات التي نطلبها من
          قبلنا واستخدامها كما هو موضح في سياسة الخصوصية هذه. يستخدم التطبيق
          خدمات تحليلية وتسويقيه خارجية والتي قد تجمع معلومات لتحديد هويتك.{' '}
        </p> */}
        <h4>تسجيل البيانات</h4>
        <p>
          {/* نود ابلاغك انه عند استخدام الخدمات المقدمة في التطبيق في حالة حدوث خطأ
          قد نقوم بجمع بعض البيانات من خلال خدمات تحليلية وتسويقيه خارجية.
          المعلومات مثل البروتوكول الخاص بك و اسم الجهاز ونظام التشغيل ومعلومات
          تكوين التطبيق ووقت وتاريخ استخدامك للخدمات واحصائيات أخرى */}
          لايتم جمع لأي بيانات من قبلنا
        </p>
        {/* <h4>الخدمات الخارجية</h4>
        <p>
          خدمات تساعدنا في تحليل كيفية استخدام التطبيق والتي لديها الحق في
          الوصول الى معلوماتك الشخصية. وذلك للمساعدة على تقديم خدمات افضل لك.
          وهذه الجهات ملزمة بعدم الكشف عن أي معلومات لجهات أخرى. أمثلة لمقدمي
          الخدمات الخارجية: 
          
        </p>
        <p>
        Google Analytics, Facebook Pixel, Snapchat Pixel …
          and More
        </p> */}
        <h4>الأمان</h4>
        <p>
          {/* نحن نقدر ثقتك في تزويدنا بمعلوماتك الشخصية ، وبالتالي فإننا نسعى
          جاهدين لاستخدام وسائل مقبولة تجاريًا لحمايتها. لكن */}
           تذكر أنه لا توجد
          وسيلة نقل عبر الإنترنت أو طريقة تخزين إلكتروني آمنة وموثوقة بنسبة 100٪
          ، ولا يمكننا ضمان أمانها المطلق
        </p>
        <h4>روابط لمواقع أخرى</h4>
        <p>
          قد تحتوي هذه الخدمة على روابط لمواقع أخرى. إذا قمت بالنقر فوق ارتباط
          لمواقع خارجية ، فسيتم توجيهك إلى هذا الموقع. لاحظ أن هذه المواقع
          الخارجية لا يتم تشغيلها بواسطتنا. لذلك ، ننصحك بشدة بمراجعة سياسة
          الخصوصية الخاصة بهذه المواقع. ليس لدينا أي سيطرة ولا نتحمل أي مسؤولية
          عن المحتوى أو سياسات الخصوصية أو الممارسات الخاصة بأي مواقع أو خدمات
          تابعة لجهات خارجية
        </p>
        <h4>تواصل معنا</h4>
        <p>عبر البريد الالكتروني info@hoicom.net</p>
      </Content>
      </Container>
    </>
  );
};

export default PrivacyAr;
