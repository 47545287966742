export const KSK_IMAGES = [
  {
    id: 1,
    url: 'images/kiosks/slides/1.png'
  },
  {
    id: 2,
    url: 'images/kiosks/slides/2.jpg'
  },
  {
    id: 3,
    url: 'images/kiosks/slides/3.jpg'
  },
  {
    id: 4,
    url: 'images/kiosks/slides/4 (1).jpg'
  },
  // {
  //   id: 5,
  //   url: 'images/kiosks/slides/5.png'
  // }
]

export const PARTNERS = [
  {
    id: 1,
    name: 'Zain',
    url: 'images/kiosks/projects/1.svg'
  },
  {
    id: 2,
    name: 'Enjaz',
    url: 'images/kiosks/projects/2.png'
  },
  {
    id: 3,
    name: 'Alinma Bank',
    url: 'images/kiosks/projects/3.svg'
  },
];

export const FEATURES = [
  {
    id: 1,
    name: 'Operations',
    url: 'images/kiosks/features/1.png'
  },
  {
    id: 2,
    name: 'Customer Care',
    url: 'images/kiosks/features/2.png'
  },
  {
    id: 3,
    name: 'Finance Reporting and Analysis',
    url: 'images/kiosks/features/3.png'
  },
  {
    id: 4,
    name: 'User Management',
    url: 'images/kiosks/features/4.png'
  },
  {
    id: 5,
    name: 'Configuration Management',
    url: 'images/kiosks/features/5.png'
  },
  {
    id: 6,
    name: 'Alert Management',
    url: 'images/kiosks/features/6.png'
  }
];
