import React, { useState } from 'react';
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from './pages/index';
import Contact from './pages/Conatct';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import PrivacyEn from './pages/PrivacyEn';
import PrivacyAr from './pages/PrivacyAr';
import Integrations from './pages/SubPages/Integrations';
import Kiosks from './pages/SubPages/Kiosks';
import Hardware from './pages/SubPages/Hardware';
import Iot from './pages/SubPages/Iot';
import ManagedServices from './pages/SubPages/ManagedServices';

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <BrowserRouter>
      {/* <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} /> */}
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/integrations' exact component={Integrations} />
        <Route path='/kiosks' exact component={Kiosks} />
        <Route path='/hardware' exact component={Hardware} />
        <Route path='/iot' exact component={Iot} />
        <Route path='/ms' exact component={ManagedServices} />
        <Route path='/contact' exact component={Contact} />
        <Route path='/privacy-en' exact component={PrivacyEn} />
        <Route path='/privacy-ar' exact component={PrivacyAr} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
