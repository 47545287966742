import React from 'react';
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './BoxesElements';

import { MdBusinessCenter, MdBusiness, MdHighQuality } from 'react-icons/md';
import { AiTwotoneAppstore, AiFillDatabase } from 'react-icons/ai';
import { GrServers } from 'react-icons/gr';
import { SiMinds } from 'react-icons/si';
//import { IoBusinessOutline } from 'react-icons/io';

const Services = () => {
  return (
    <ServicesContainer>
      <ServicesH1>Our Solutions</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon>
            <MdBusinessCenter style={{ fontSize: 95 }} />
          </ServicesIcon>
          <ServicesH2>Integration Services</ServicesH2>
          <ServicesP>
          HOI Consulting specializes in creating business value through technology. As experts in the field of integration and implementation, we transform your technology to become an integrated framework providing efficiencies and business advantages.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon>
            <AiTwotoneAppstore style={{ fontSize: 95 }} />
          </ServicesIcon>
          <ServicesH2>Hardware Services</ServicesH2>
          <ServicesP>
          HOI creates value for customers and partners and accelerates the digital transformation of the industry in Saudi Arabia.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon>
            <AiFillDatabase style={{ fontSize: 75 }} />
          </ServicesIcon>
          <ServicesH2>AI Smart Home Solutions</ServicesH2>
          <ServicesP>
          HOI provides a variety of smart home hardware options, including different types of hardware such as security, remote control, sensing, lighting, etc., and supports customized development of different connection technologies, which is convenient for customers to create according to the use environment Own smart home technology.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon>
            <MdHighQuality style={{ fontSize: 95 }} />
          </ServicesIcon>
          <ServicesH2>Mobile VAS / Mobile Solutions</ServicesH2>
          <ServicesP>
          HOI develops products and technologies that enable operators to provide – and subscribers to enjoy – an enhanced mobile experience. Our technologies and partnerships allow us to provide the highest-quality mobile content to millions of customers through their handset everyday. Leading mobile operators in the region use HOI solutions and serve millions of mobile users.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon>
            <SiMinds style={{ fontSize: 95 }} />
          </ServicesIcon>
          <ServicesH2>Business Intelligence</ServicesH2>
          <ServicesP>
            HOI’s Business Intelligence Services are based on a proven
            methodology that provides accurate and timely information based on
            key metrics, emerging trends and any data source throughout your
            organization.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon>
            <MdBusiness style={{ fontSize: 95 }} />
          </ServicesIcon>
          <ServicesH2>Asset Management / Physical Inventory</ServicesH2>
          <ServicesP>
          Using the latest state-of-the-art-barcode technology, you’ll be enabled to take accurate physical inventories quickly, get increased control and have access to reliable information for planning new fixed assets investments. Data is quickly and efficiently reconciled – giving you unprecedented control of your entire company’s fixed assets.
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
