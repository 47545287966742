export const INT_IMAGES = [
  {
    id: 1,
    url: 'images/integrations/slides/1.jpg',
  },
  // {
  //   id: 2,
  //   url: 'images/integrations/slides/2.jpg',
  // },
  {
    id: 3,
    url: 'images/integrations/slides/3.jpg',
  },
  {
    id: 4,
    url: 'images/integrations/slides/4.jpg',
  }
]