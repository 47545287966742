export const PROVIDERS = [
  {
    id: 1,
    name: 'Airtel India',
    url: 'images/integrations/topup/1.svg'
  },
  {
    id: 2,
    name: 'Ufone Pakistan',
    url: 'images/integrations/topup/2.png'
  },
  {
    id: 3,
    name: 'NCell Nepal',
    url: 'images/integrations/topup/3.png'
  },
  {
    id: 4,
    name: 'Banglalink Bangladesh',
    url: 'images/integrations/topup/4.png'
  },
  {
    id: 5,
    name: 'Jazz Pakistan',
    url: 'images/integrations/topup/5.png'
  },
  {
    id: 6,
    name: 'MTNL India',
    url: 'images/integrations/topup/6.png'
  },
  {
    id: 7,
    name: 'Vodafone Egypt',
    url: 'images/integrations/topup/7.png'
  },
  {
    id: 8,
    name: 'Telenor Pakistan',
    url: 'images/integrations/topup/8.png'
  },
  {
    id: 9,
    name: 'Vi India',
    url: 'images/integrations/topup/9.png'
  },
  {
    id: 10,
    name: 'GrameenPhone Bangladesh',
    url: 'images/integrations/topup/10.png'
  },
]