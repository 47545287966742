export const IOT_SLIDES = [
  {
    id: 1,
    url: 'images/iot/slides/1.jpg',
  },
  {
    id: 2,
    url: 'images/iot/slides/2.jpg',
  },
  {
    id: 3,
    url: 'images/iot/slides/3.jpg',
  },
  {
    id: 4,
    url: 'images/iot/slides/4.jpg',
  },
  {
    id: 5,
    url: 'images/iot/slides/5.jpg',
  }
]

export const IOT_SERVICES = [
  {
    id: 1,
    url: 'images/iot/smart_homes/1.png',
    name: 'High Flexibility in choosing wireless IoT Network Protocols'
  },
  {
    id: 2,
    url: 'images/iot/smart_homes/2.png',
    name: 'High Flexibility and Reliability in device upgrade'
  },
  {
    id: 3,
    url: 'images/iot/smart_homes/3.png',
    name: 'Unified object template'
  },
  {
    id: 4,
    url: 'images/iot/smart_homes/4.png',
    name: 'Open API Capability'
  },
  {
    id: 5,
    url: 'images/iot/smart_homes/5.png',
    name: 'Smart Campus'
  },
  {
    id: 6,
    url: 'images/iot/smart_homes/6.png',
    name: 'High Data Security'
  }
]