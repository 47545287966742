export const VOUCHERS = [
  {
    id: 1,
    name: 'Playstation Network',
    url: 'images/integrations/vouchers/1.png'
  },
  {
    id: 2,
    name: 'Google Play',
    url: 'images/integrations/vouchers/2.png'
  },
  {
    id: 3,
    name: 'App Store & iTunes Gift Card',
    url: 'images/integrations/vouchers/3.png'
  },
  {
    id: 4,
    name: 'Xbox',
    url: 'images/integrations/vouchers/4.png'
  },
  {
    id: 5,
    name: 'Steam',
    url: 'images/integrations/vouchers/5.png'
  },
  {
    id: 6,
    name: 'Amazon',
    url: 'images/integrations/vouchers/6.png'
  },
  {
    id: 7,
    name: 'Skype',
    url: 'images/integrations/vouchers/7.png'
  },
  {
    id: 8,
    name: 'Spotify',
    url: 'images/integrations/vouchers/8.png'
  },
  {
    id: 9,
    name: 'Netflix',
    url: 'images/integrations/vouchers/9.png'
  }
]