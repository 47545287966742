import React, { useState } from 'react';

import Video from '../../videos/video.mp4';
import Video1 from '../../videos/video1.mp4'
import { Button } from '../ButtonElement';
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight
} from './HeroElements';

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer id='home'>
      <HeroBg>
        <VideoBg
          autoPlay
          playsInline
          loop
          muted
          src={Video1}
          type='video/mp4'
        />
      </HeroBg>
      <HeroContent>
        {/* <HeroH1>HOI</HeroH1> */}
        <HeroP>House of Invention CIT</HeroP>
        {/* <HeroBtnWrapper>
          <Button
            to='/contact'
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            // primary
            // dark
          >
            Contact Us {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper> */}
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
