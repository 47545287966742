import React from 'react';
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './ClientsElements';
import styled from 'styled-components';
import XFusion from '../../images/clients/xfusion.svg';
import IBS from '../../images/clients/ibs.svg';
import stclogo from '../../images/clients/stclogo.svg'
import ChinaMobile from '../../images/clients/china-mobile.svg';
import citc_logo from '../../images/clients/citc_logo.svg';
import holowitslogo from '../../images/clients/holowitslogo.svg';
import inma from '../../images/clients/inma.svg';
import mobily from '../../images/clients/mobily.svg';
import airbus_logo from '../../images/clients/airbus_logo.svg';
import bilad_logo  from '../../images/clients/bilad_logo.svg';
import zain_logo  from '../../images/clients/zain_logo.svg';
import  aramco from '../../images/clients/aramco.svg';
import stc_bank  from '../../images/clients/stc_bank.svg';
import barq  from '../../images/clients/barq.svg';
import enjaz  from '../../images/clients/enjaz.svg';


const Img = styled.img`
  height: 160px;
  width: 160px;
`;

const Clients = () => {
  return (
    <ServicesContainer id='clients'>
      <ServicesH1>Our Clients/Partners</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon>
            <Img src={stclogo} />
          </ServicesIcon>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon>
            <Img src={barq} />
          </ServicesIcon>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon>
            <Img src={stc_bank} />
          </ServicesIcon>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon>
            <Img src={citc_logo} />
          </ServicesIcon>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon>
            <Img src={zain_logo} />
          </ServicesIcon>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon>
            <Img src={inma} />
          </ServicesIcon>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon>
            <Img src={mobily} />
          </ServicesIcon>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon>
            <Img src={aramco} />
          </ServicesIcon>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon>
            <Img src={IBS} />
          </ServicesIcon>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon>
            <Img src={enjaz} />
          </ServicesIcon>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Clients;
