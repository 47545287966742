import React from 'react';
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink
} from './NavbarElements';
import logo from '../../images/hoilogo.png'; 

import { FaBars } from 'react-icons/fa';

const NavbarContact = ({ toggle }) => {
  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo to='/'>
          {/* HOI */}
          <img src={logo} alt="logo"  height={50} />

          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          {/* <NavMenu>
            <NavItem>
              <NavLinks to='/'>About Us</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='/'>Solutions</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='/'>Services</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='/'>Clients</NavLinks>
            </NavItem>
          </NavMenu> */}
          <NavBtn>
            <NavBtnLink to='/'>Home</NavBtnLink>
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default NavbarContact;
