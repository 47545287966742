import styled from 'styled-components';

import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';

export const Nav = styled.nav`
  //background: #000062;
  background-color: transparent;
  height: 80px;
  width: 100%;
  /* margin-top: -80px; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: absolute;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 960px) {
    transition: 0ms.8s all ease;
  }
`;

export const LogoContainer = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
    color: #fff;
  }
`;

export const MenuContainer = styled.div`
  width: 60%;
  height: 100%;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContactContainer = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`;

export const NavLogo = styled(LinkR)`
  color: #fff;
  justify-content: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: 900;
  text-decoration: none;
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
`;

export const NavMenu = styled.ul`
  /* display: flex; */
  /* flex-direction: flex-end; */
  list-style: none;
  margin-bottom: 0px;
  /* text-align: center;
  margin-right: 0px; */

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  /* height: 80px; */
  display: inline-block;
  padding: 0 1rem;
`;

export const NavLinks = styled(LinkS)`
  color: #fff;
  /* display: flex;
  align-items: center; */
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
  //font-weight: bold;
  
  p {
    font-size: 30px;
  }

  &:active, &:hover {
    color: #fff;
    border-bottom: 3px solid #fff;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 30px;
  border: 1px solid #fff;
  border-radius: 50px;
  display: none;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    display: inline;
  }
`;


export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  background: transparent;
  white-space: nowrap;
  padding: 10px 22px;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #000062;
  }
`;