import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

// import '~slick-carousel/slick/slick.css';
// import '~slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Slick = ({ title, data }) => {
  var settings = {
    dots: true,
    infinite: data.length < 4 ? false : true,
    speed: 500,
    slidesToShow: data.length < 4 ? 2 : 4,
    slidesToScroll: data.length < 4 ? 2 : 4,
    autoplay: data.length < 4 ? false : true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: data.length < 4 ? 2 : 3,
          slidesToScroll: data.length < 4 ? 2 : 3,
          infinite: data.length < 4 ? false : true,
          dots: data.length < 4 ? false : true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Container>
      <SliderContainer>
        <h2>{title}</h2>
        <Slider {...settings}>
          {data.map(item => (
            <ItemContainer>
              {item.url && (
                <ImageContainer>
                  <img src={item.url} />
                </ImageContainer>
              )}
              <TitleContainer>
                <p>{item.name}</p>
              </TitleContainer>
            </ItemContainer>
          ))}
        </Slider>
      </SliderContainer>
    </Container>
  );
};

export default Slick;

const Container = styled.div`
  width: 100%;
  /* height: 30vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
`;

const SliderContainer = styled.div`
  width: 95%;
  h2 {
    font-weight: bold;
    text-align: center;
  }

  
`;

const ItemContainer = styled.div`
  height: 200px;
  width: 150px;
  //background: blue;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
`;

const ImageContainer = styled.div`
  height: 70%;
  width: 90%;
  padding: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    height: 100%;
    width: 80%;
    object-fit: contain;
  }
`;

const TitleContainer = styled.div`
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    text-align: center;
  }
`;
