import React from 'react';
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './ServicesElements';
import { useHistory } from 'react-router-dom';

import {
  MdBusinessCenter,
  MdBusiness,
  MdHighQuality,
  MdSubscriptions,
  MdSettings
} from 'react-icons/md';
import { AiTwotoneAppstore, AiFillDatabase, AiFillApi } from 'react-icons/ai';
import { SiMinds } from 'react-icons/si';
import { FaServer } from 'react-icons/fa';
import { RiHomeWifiFill, RiListSettingsFill } from 'react-icons/ri';
import { TbApi } from 'react-icons/ti';
import { GrVend, GrHostMaintenance } from 'react-icons/gr';

const Services = () => {
  const history = useHistory();
  return (
    <ServicesContainer id='services'>
      <ServicesH1>Our Solutions</ServicesH1>
      <ServicesWrapper>
        <ServicesCard
          onClick={() => {
            history.push('/integrations');
          }}
        >
          <ServicesIcon>
            <MdSettings style={{ fontSize: 95 }} />
          </ServicesIcon>
          <ServicesH2>Integration Services</ServicesH2>
          <ServicesP>
            HOI Consulting specializes in creating business value through
            technology to become an integrated framework providing efficiencies
            and business advantages.
          </ServicesP>
        </ServicesCard>
        <ServicesCard
          onClick={() => {
            history.push('/hardware');
          }}
        >
          <ServicesIcon>
            <FaServer style={{ fontSize: 95 }} />
          </ServicesIcon>
          <ServicesH2>Hardware Services</ServicesH2>
          <ServicesP>
            HOI creates value for customers and partners and accelerates the
            digital transformation of the industry in Saudi Arabia.
          </ServicesP>
        </ServicesCard>
        <ServicesCard
          onClick={() => {
            history.push('/iot');
          }}
        >
          <ServicesIcon>
            <RiHomeWifiFill style={{ fontSize: 75 }} />
          </ServicesIcon>
          <ServicesH2>IoT</ServicesH2>
          <ServicesP>
            HOI provides a variety of smart home hardware options and supports
            customized development of different connection technologies.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon>
            <MdSubscriptions style={{ fontSize: 95 }} />
          </ServicesIcon>
          <ServicesH2>Mobile VAS Solutions</ServicesH2>
          <ServicesP>
            HOI developing products and technologies that enable operators to
            provide subscription services with the highest-quality content to
            considerable of customers through their handset.
          </ServicesP>
        </ServicesCard>
        <ServicesCard
          onClick={() => {
            history.push('/ms');
          }}
        >
          <ServicesIcon>
            <GrHostMaintenance style={{ fontSize: 95 }} />
          </ServicesIcon>
          <ServicesH2>Managed Services</ServicesH2>
          <ServicesP>
            Empower your business with HOI powerful Managed Services
          </ServicesP>
        </ServicesCard>
        <ServicesCard
          onClick={() => {
            history.push('/kiosks');
          }}
        >
          <ServicesIcon>
            <GrVend style={{ fontSize: 95 }} />
          </ServicesIcon>
          <ServicesH2>Kiosk</ServicesH2>
          <ServicesP>
            Brings automation to life, transforming operational efficiency and
            elevating daily customer experiences.
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
