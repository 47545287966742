import { useEffect } from 'react';

import Navbar from '../../components/Shared/Navbar/index';
import Slides from '../../components/Shared/Slides';
import Slick from '../../components/Shared/Slick';
import {
  HRD_SLIDES,
  RACK,
  HIGH_DENSITY,
  HET_SERVERS,
  CAMERAS
} from '../../data/hardware/data';

import { KSK_IMAGES } from '../../data/kiosks/data';

const Hardware = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <Slides title='Hardware Solutions' data={HRD_SLIDES} />
      <Slick title='Rack Servers' data={RACK} />
      <Slick title='High density servers' data={HIGH_DENSITY} />
      <Slick title='Heterogeneous servers' data={HET_SERVERS} />
      <Slick title='Software Defined Cameras' data={CAMERAS} />
    </>
  );
};

export default Hardware;
