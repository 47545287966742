import React from 'react';
import styled from 'styled-components';

const Row = ({ title, data }) => {
  return (
    <Container>
      <TitleContainer>
        <h2>{title}</h2>
      </TitleContainer>
      <ItemsContainer>
        {data.map(item => (
          <Item>
            <ItemImageContainer>
              <img src={item.url} />
            </ItemImageContainer>
            <ItemTitleContainer>
              <p>{item.name}</p>
            </ItemTitleContainer>
          </Item>
        ))}
      </ItemsContainer>
    </Container>
  );
};

export default Row;

const Container = styled.div`
  width: 100%;
  /* height: 30vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
`;

const TitleContainer = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ItemsContainer = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px;

  @media screen and (max-width: 999px) {
    flex-direction: column;
    height: 600px;
  }
`;

const Item = styled.div`
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 999px) {
    width: 100%;
    height: 50%;
  }
`;

const ItemImageContainer = styled.div`
  height: 80%;
  width: 100%;
  padding: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    height: 100%;
    width: 80%;
    object-fit: contain;
  }
`;

const ItemTitleContainer = styled.div`
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
