import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import Sidebar from '../components/Sidebar';
import NavbarContact from '../components/NavbarContact';

const Container = styled.div`
  width: 100%;
  padding: 40px 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2% 10%;
    h2 {
      text-align: center;
      font-family: 'Cairo', sans-serif;
      text-decoration: underline;
    }
    h4 {
      text-align: center;
      font-family: 'Cairo', sans-serif;
    }
    p {
      text-align: center;
      font-family: 'Cairo', sans-serif;
    }
  `;

const PrivacyEn = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <NavbarContact toggle={toggle} />
      <Container>
      <Content>
        <h2>Privacy Policy</h2>{' '}
        <p>
          This privacy notice describes how we might use your information if
          you: 1- Download or use our mobile Application - Moodac 2- Engage with
          us in other related ways – including any sales, marketing, or events
        </p>{' '}
        <p>
          This page is used to inform visitors regarding our policies with the
          collection, use, and disclosure of Personal Information if anyone
          decided to use our Service.
        </p>{' '}
        <p>
          If you choose to use our Service, then you agree to the collection and
          use of information in relation to this policy. 
          {/* The Personal
          Information that we collect is used for providing and improving the
          Service. We will not use or share your information with anyone except
          as described in this Privacy Policy. */}
        </p>{' '}
        <h4>
          <strong>Information Collection and Use</strong>
        </h4>{' '}
        <p>
          {/* For a better experience, while using our Service, we may require you
          to provide us with certain personally identifiable information. The
          information that we request will be retained by us and used as
          described in this privacy policy. */}
          We do not collect any data from our users.
        </p>{' '}
        <div>
          <p>
            {/* The app does use third party services that may collect information
            used to identify you. */}
          </p>{' '}
        </div>{' '}
        {/* <h4>
          <strong>Log Data</strong>
        </h4>{' '}
        <p>
          We want to inform you that whenever you use our Service, in a case of
          an error in the app we collect data and information (through third
          party products) on your phone called Log Data. This Log Data may
          include information such as your device Internet Protocol (“IP”)
          address, device name, operating system version, the configuration of
          the app when utilizing our Service, the time and date of your use of
          the Service, and other statistics.
        </p>{' '}
        <h4>
          <strong>Service Providers</strong>
        </h4>{' '}
        <p>
          We may employ third-party companies and individuals due to the
          following reasons:
        </p>{' '}
        <ul>
          <li>To facilitate our Service;</li>{' '}
          <li>To provide the Service on our behalf;</li>{' '}
          <li>To perform Service-related services; or</li>{' '}
          <li>To assist us in analyzing how our Service is used.</li>
        </ul>{' '}
        <p>
          We want to inform users of this Service that these third parties have
          access to your Personal Information. The reason is to perform the
          tasks assigned to them on our behalf. However, they are obligated not
          to disclose or use the information for any other purpose.
        </p>{' '} */}
        <h4>
          <strong>Security</strong>
        </h4>{' '}
        <p>
          {/* We value your trust in providing us your Personal Information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and we
          cannot guarantee its absolute security. */}
          We value your trust in using our app. 
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and we
          cannot guarantee its absolute security.
        </p>{' '}
        <h4>
          <strong>Links to Other Sites</strong>
        </h4>{' '}
        <p>
          This Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by us. Therefore, we strongly advise
          you to review the Privacy Policy of these websites. We have no control
          over and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services.
        </p>{' '}
        <h4>
          <strong>Contact Us</strong>
        </h4>{' '}
        <p>
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us at info@hoicom.net.
        </p>{' '}
      </Content>
      </Container>
    </>
  );
};

export default PrivacyEn;
