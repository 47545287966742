import { useEffect } from 'react';

import Navbar from '../../components/Shared/Navbar/index';
import Slides from '../../components/Shared/Slides';
import Slick from '../../components/Shared/Slick';

import { IOT_SLIDES, IOT_SERVICES } from '../../data/iot/data';

const Iot = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      <Navbar />
      <Slides title='IoT Solutions' data={IOT_SLIDES} />
      <Slick title='Smart Home IoT' data={IOT_SERVICES} />
      {/* <Slick title='High density servers' data={HIGH_DENSITY} />
      <Slick title='Heterogeneous servers' data={HET_SERVERS} /> */}
    </>
  )
}

export default Iot