import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { IoMdDoneAll } from 'react-icons/io';

const VMV = () => {
  return (
    <Container>
      <Content>
        <Column>
          <TitleContainer>
            <Title>Our Vision</Title>
          </TitleContainer>
          <TxtCont>
            <Text>
              HOI’s vision is to maintain a long-term relationship with its
              clients based on accuracy and efficiency in servicing within
              slated time frames, high-quality service, and effective support.
            </Text>
          </TxtCont>
          <TxtCont>
            <Text>
              In HOI, we are fully committed to achieve the standard of our
              products and services through our Quality Management Department,
              which aims to satisfy our customers’ expectations.
            </Text>
          </TxtCont>
        </Column>
        <Column>
          <TitleContainer>
            <Title>Our Mission</Title>
          </TitleContainer>
          <TxtCont>
            <Text>
              HOI's mission is complete dedication to provide authentic
              state-of-art products, services and solutions with a vision to
              make way into new areas of technology, competence for the benefit
              of our customers as well as related business and industry sectors.
            </Text>
          </TxtCont>
        </Column>
        <Column>
          <TitleContainer>
            <Title>Our Values</Title>
          </TitleContainer>
          <TxtCont>
            <Text>
              <IoMdDoneAll /> Partnership
            </Text>
          </TxtCont>
          <TxtCont>
            <Text>
              <IoMdDoneAll /> Honesty
            </Text>
          </TxtCont>
          <TxtCont>
            <Text>
              <IoMdDoneAll /> Integrity
            </Text>
          </TxtCont>
          <TxtCont>
            <Text>
              <IoMdDoneAll /> Professionalism
            </Text>
          </TxtCont>
          <TxtCont>
            <Text>
              <IoMdDoneAll /> Commitment
            </Text>
          </TxtCont>
          <TxtCont>
            <Text>
              <IoMdDoneAll /> Quality & Continual Improvement
            </Text>
          </TxtCont>
          <TxtCont>
            <Text>
              <IoMdDoneAll /> Innovation
            </Text>
          </TxtCont>
        </Column>
      </Content>
    </Container>
  );
};

export default VMV;

const Container = styled.div`
  width: 100%;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`;

const Content = styled.div`
  width: 100%;
  padding: 20px 5%;
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  width: 33%;
  padding: 10px 5px;
  margin: 0 5px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  //border-radius: 10px;
  //box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    margin: 5px 0;
    width: 100%;
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  padding: 8px 0;
  display: flex;
  justify-content: center;
  color: #000;
`;

const Title = styled.h3``;

const TxtCont = styled.div`
  padding: 0 10px;
  margin: 7px 0;
  width: 80%;
  display: flex;
  //justify-content: center;
`;

const Text = styled.p`
  text-align: left;
  font-size: 15px;
  color: #000;
`;
