export const HRD_SLIDES = [
  {
    id: 1,
    url: 'images/hardware/slides/1.jpg'
  },
  {
    id: 2,
    url: 'images/hardware/slides/2.jpg'
  },
  {
    id: 3,
    url: 'images/hardware/slides/3.jpg'
  }
];

export const RACK = [
  {
    id: 1,
    name: '1288H V5/V6 General purpose',
    url: 'images/hardware/rack/1.png'
  },
  {
    id: 2,
    name: '2288H V5/V6 Balanced',
    url: 'images/hardware/rack/2.png'
  },
  {
    id: 3,
    name: '2298 V5 Storage intensive',
    url: 'images/hardware/rack/3.jpg'
  },
  {
    id: 4,
    name: '5288 V5/V6 intensive',
    url: 'images/hardware/rack/4.jpg'
  },
  {
    id: 5,
    name: '2488 V5&2488H V5/V6 Compute-intensive',
    url: 'images/hardware/rack/5.png'
  },
  {
    id: 6,
    name: '5885H V5 Mission critical',
    url: 'images/hardware/rack/6.png'
  }
];

export const HIGH_DENSITY = [
  {
    id: 1,
    name: '2U 4 node 24 disks X6000 Chassis',
    url: 'images/hardware/high/1.png'
  },
  {
    id: 2,
    name: '2U 4 node 8 disks X6000 Chassis',
    url: 'images/hardware/high/2.png'
  },
  {
    id: 3,
    name: 'Air cooled node XH321 V5/V6 X6000 Compute intensive node',
    url: 'images/hardware/high/3.png'
  },
  {
    id: 4,
    name: 'Liquid cooled node XH321L V5/XH321C V6 X6000 Compute intensive node',
    url: 'images/hardware/high/4.png'
  }
];

export const HET_SERVERS = [
  {
    id: 1,
    name: 'G5500 full width server',
    url: 'images/hardware/het/1.jpg'
  },
  {
    id: 2,
    name: 'G5500 half width server',
    url: 'images/hardware/het/2.png'
  },
  {
    id: 3,
    name: 'G560 full width Heterogeneous computing node',
    url: 'images/hardware/het/3.png'
  },
  {
    id: 4,
    name: 'G530G560 half width Heterogeneous computing node',
    url: 'images/hardware/het/4.png'
  }
];

export const CAMERAS = [
  {
    id: 1,
    name: 'Bullet Camera',
    url: 'images/hardware/camera/1.png'
  },
  {
    id: 2,
    name: 'Fixed Dome Camera',
    url: 'images/hardware/camera/2.png'
  },
  {
    id: 3,
    name: 'PTZ Dome Camera',
    url: 'images/hardware/camera/3.png'
  },
  {
    id: 4,
    name: 'NVR 64-channel intelligent Micro Edge',
    url: 'images/hardware/camera/4.png'
  }
];