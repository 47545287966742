import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
  LogoContainer,
  MenuContainer,
  ContactContainer
} from './NavbarElements';
import logo from '../../../images/hoilogo.png'; 

import { FaBars } from 'react-icons/fa';

const Navbar = ({ toggle }) => {
  const history = useHistory();
  return (
    <>
      <Nav>
        <LogoContainer>
          <h3
            onClick={() => {
              history.push('/');
            }}
          >
          <img src={logo} alt="logo"  height={50} />
          </h3>
        </LogoContainer>
        <MenuContainer>
          <NavMenu>
            <NavItem
              
            >
              <NavLinks onClick={() => {
                history.push('/');
              }} to='/'>About Us</NavLinks>
            </NavItem>
            <NavItem
              
            >
              <NavLinks onClick={() => {
                history.push('/');
              }} to='/'>Solutions</NavLinks>
            </NavItem>
            <NavItem
              
            >
              <NavLinks onClick={() => {
                history.push('/');
              }} to='/'>Clients</NavLinks>
            </NavItem>
            <NavItem
              
            >
              <NavLinks onClick={() => {
                history.push('/contact');
              }} to='/contact'>Contact</NavLinks>
            </NavItem>
          </NavMenu>
        </MenuContainer>

        <ContactContainer>
          {/* <NavBtn>
            <NavBtnLink to='/contact'>Contact Us</NavBtnLink>
          </NavBtn> */}
        </ContactContainer>
        {/* <NavbarContainer>
          <NavLogo to='/'>HOI</NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks to='about'>About Us</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='solutions'>Solutions</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='services'>Services</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to='clients'>Clients</NavLinks>
            </NavItem>
          </NavMenu>
          <NavBtn>
            <NavBtnLink to='/contact'>Contact Us</NavBtnLink>
          </NavBtn>
        </NavbarContainer> */}
      </Nav>
    </>
  );
};

export default Navbar;
