import { useEffect } from 'react';

import Navbar from '../../components/Shared/Navbar/index';
import Slides from '../../components/Shared/Slides';
import Slick from '../../components/Shared/Slick';
import { MANAGED_SERVICES, MS_SLIDES } from '../../data/mservices/data';

const ManagedServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      <Navbar />
      <Slides title='Managed Services' data={MS_SLIDES} />
      <Slick title='' data={MANAGED_SERVICES} />
    </>
  );
};

export default ManagedServices;
