import { Carousel } from 'react-bootstrap';
import styled from 'styled-components';

const Slides = ({ title, data }) => {
  return (
    <Container>
      <TitleContainer>
        <h2>{title}</h2>
      </TitleContainer>
      <Carousel>
        {data.map(item => (
          <Carousel.Item interval={3000}>
            <img className='d-block w-100' src={item.url} alt='First slide' />
            <Carousel.Caption>
              {/* <h3>Commiment</h3>
            <p>
              stability and sustainability are fundamental part in our work.
            </p> */}
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
};

export default Slides;

const Container = styled.div`
  height: 80vh;
  width: 100%;
  background: #000;
  position: relative;

  img {
    height: 80vh;
    width: 100%;
    object-fit: cover;
    opacity: 0.5;
  }

  @media screen and (max-width: 768px) {
    height: 40vh;

    img {
    height: 40vh;
  }
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 35vh;
  //z-index: 1;

  h2 {
    color: white;
    font-weight: bold;
    font-size: 45px;
  }

  @media screen and (max-width: 768px) {
    top: 18vh;
    h2 {
      font-size: 25px;
    }
  }
`;

const Title = styled.div`
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 1;

  h2 {
    color: white;
  }
`;
