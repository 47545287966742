import styled from 'styled-components';

export const ServicesContainer = styled.div`
  /* height: 800px; */
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;

  @media screen and (max-width: 768px) {
    //height: 1100px;
    padding: 20px 0;
  }

  @media screen and (max-width: 480px) {
    //height: 1300px;
    padding: 20px 0;
  }
`;

export const ServicesWrapper = styled.div`
  //max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 25px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const ServicesCard = styled.div`
  background: #F5F5F5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  //border-radius: 10px;
  height: 220px;
  width: 220px;
  padding: 20px;
  //box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const ServicesIcon = styled.div`
  height: 240px;
  width: 240px;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  margin-bottom: 10px;
`;

export const ServicesH1 = styled.div`
  font-size: 2.5rem;
  color: #000;
  margin-bottom: 64px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const ServicesH2 = styled.div`
  font-size: 1rem;
  color: #000;
  margin-bottom: 10px;
`;

export const ServicesP = styled.div`
  font-size: 12px;
  text-align: center;
`;
