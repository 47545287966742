import React from "react";
import "./Footer.css";
import { Link } from "react-scroll";
import { FaTwitter, FaLinkedin } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";

function Footer() {
  return (
    <div className="footer-container">
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link to="/" className="social-logo">
              <h3>HOI</h3>
            </Link>
          </div>
          <small className="website-rights">
            Copyright ©2022 All rights reserved by HOI
          </small>
          <div className="social-icons">
            <a href="https://www.instagram.com/hoi_ksa/" target="_blank">
              <RiInstagramFill className="social-icon-link" />
            </a>
            <a href="https://twitter.com/hoi_ksa" target="_blank">
              <FaTwitter className="social-icon-link" />
            </a>
            <a href="https://www.linkedin.com/company/hoi_ksa/" target="_blank">
              <FaLinkedin className="social-icon-link" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
