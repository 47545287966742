import { useEffect } from 'react';

import Navbar from '../../components/Shared/Navbar/index';
import Slides from '../../components/Shared/Slides';
import Boxes from '../../components/Shared/Boxes/index';
import Slick from '../../components/Shared/Slick';
import Row from '../../components/Shared/Row';

import { KSK_IMAGES, PARTNERS, FEATURES } from '../../data/kiosks/data';

const Kiosks = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      <Navbar />
      <Slides title='Self Service Machine' data={KSK_IMAGES} />
      <Row title='Projects' data={PARTNERS} />
      <Slick title='Main Features' data={FEATURES} />
    </>
  )
}

export default Kiosks