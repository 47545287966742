export const MS_SLIDES = [
  {
    id: 1,
    name: 'Managed Server Support',
    url: 'images/mservices/slides/1.jpg'
  },
  {
    id: 2,
    name: 'Managed Storage and Backup',
    url: 'images/mservices/slides/2.jpg'
  },
  // {
  //   id: 3,
  //   name: 'Managed Database',
  //   url: 'images/mservices/slides/3.jpg'
  // },
  {
    id: 4,
    name: 'IT Service Management',
    url: 'images/mservices/slides/4.jpg'
  }
]

export const MANAGED_SERVICES = [
  {
    id: 1,
    name: 'Managed Server Support',
    url: 'images/mservices/mservices/1.png'
  },
  {
    id: 2,
    name: 'Managed Storage and Backup',
    url: 'images/mservices/mservices/2.png'
  },
  {
    id: 3,
    name: 'Managed Database',
    url: 'images/mservices/mservices/3.png'
  },
  {
    id: 4,
    name: 'IT Service Management',
    url: 'images/mservices/mservices/4.png'
  }
];