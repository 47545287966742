import React, { useState} from 'react';
import HeroSection from '../components/HeroSection';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Services from '../components/Services';
import AboutUs from '../components/AboutUs';
import Clients from '../components/Clients';
import VMV from '../components/VMV/VMV';
import Solutions from '../components/Solutions/Solutions';

const Home = () => {
  // const aboutRef = useRef(null);
  // const location = useLocation();

  // useEffect(() => {
  //   if (location.state.el) {
  //     const el = location.state.el;
  //     if (el == 'about') {
  //       const executeScroll = () => aboutRef.current.scrollIntoView({ behavior: 'smooth' });
  //       executeScroll();
  //     }
  //     //animateScroll.scrollTo(location.state.el);
  //   }
  //   return () => {};
  // }, [location]);

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <AboutUs />
      <Solutions />
      {/* <Fade bottom>
        <InfoSection {...homeObjOne} />
      </Fade> */}

      {/* <SolutionsHeader />
      <Fade bottom>
        <InfoSection {...homeObjTwo} />
      </Fade>
      <Fade bottom>
        <InfoSection {...homeObjThree} />
      </Fade>
      <Fade bottom>
        <InfoSection {...homeObjFour} />
      </Fade> */}
      {/* <Fade bottom> */}
        <Services />
      {/* </Fade> */}
      {/* <Fade bottom> */}
        <Clients />
      {/* </Fade> */}

      {/* <Contact /> */}
      {/* <Fade bottom> */}
        <VMV />
      {/* </Fade> */}
    </>
  );
};

export default Home;
