import { Carousel } from 'react-bootstrap';
import styled from 'styled-components';

const Solutions = () => {
  return (
    <Container id='solutions'>
      {/* <Title>
        <h2>Our Solutions</h2>
      </Title> */}
      <Carousel>
        <Carousel.Item interval={5000}>
          <img
            className='d-block w-100'
            src='https://img.freepik.com/free-photo/close-up-hand-holding-smartphone_23-2149151162.jpg?t=st=1656589678~exp=1656590278~hmac=5d8ca8af016537906a2aa656243cf32f5131b0ad83c1344d3c13e215eedc145b&w=1380'
            alt='First slide'
          />
          <Carousel.Caption style={{ bottom: '12vh' }}>
            <h3>Commitment</h3>
            <p>
              Stability and sustainability are fundamental parts of success.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <img
            className='d-block w-100'
            src='https://img.freepik.com/free-photo/cloud-computing-banner-background-smart-city_53876-108504.jpg?t=st=1656498611~exp=1656499211~hmac=5ba53d3821d303e6056bb7b07aaa2b48bd4ab4477f3c4effcadc23f7bed40975&w=1380'
            alt='Second slide'
          />
          <Carousel.Caption style={{ bottom: '12vh' }}>
            <h3>Quality</h3>
            <p>We are always able to meet our client's requirements.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <img
            className='d-block w-100'
            src='https://img.freepik.com/free-vector/cloud-internet-technology_53876-94170.jpg?t=st=1656589486~exp=1656590086~hmac=3dd5db6a81cb97c9a3ba32ab88795df43e0b4ea14efa3492d7c94946f595c9f0&w=1380'
            alt='Third slide'
          />
          <Carousel.Caption style={{ bottom: '12vh' }}>
            <h3>Knowledge</h3>
            <p>Constant desire to learn is one of our principles.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <img
            className='d-block w-100'
            src='https://img.freepik.com/free-photo/cloud-storage-banner-background_53876-108506.jpg?t=st=1656589436~exp=1656590036~hmac=db8812056afd7216cd59412f8bb2c5cf851de942f2e6ecb9b8f6a402d0e29946&w=1380'
            alt='Third slide'
          />
          <Carousel.Caption style={{ bottom: '12vh' }}>
            <h3>Flexibility</h3>
            <p>
              We overcome problems and accept changes with an open-minded
              approach.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </Container>
  );
};

export default Solutions;

const Container = styled.div`
  height: 40vh;
  width: 100%;
  background: #000;
  position: relative;

  img {
    height: 40vh;
    width: 100%;
    object-fit: cover;
    opacity: 0.4;
  }
`;

const SliderItemsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 15vh;
  z-index: 0;

  h2 {
    color: white;
  }
`;
